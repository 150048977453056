import AppConfiguration from 'src/types/appConfiguration';

export default {
  reactAppApiBaseUrl: 'https://apireader.tdm.bybillon.com',
  reactAppUserIdentityApiBaseUrl: 'https://apirteven.uat.tdm.bybillon.com',
  reactAppNationalNodeApiBaseUrl: 'https://wk.uat.tdm.bybillon.com/',
  siteTitle: 'SITE_TITLE_BILLON_UNIFIED_BLOCKCHAIN',
  documentPrivate: true,
  documentPublic: true,
  documentVerification: true,
  documentRemoteSigning: false,
  userLogin: true,
  userRegistration: true,
  userResetPassword: false,
  mockEndpoints: false,
  diploma: false,
  documentPrivateCategories: true,
  envPrefix: 'PROD'
} as AppConfiguration;
